import React from 'react'
import LinkButton from '../../../component/buttons/LinkButton'

function Card({ title, license_plate, imagesrc, active, linkurl }) {
    // Card component for displaying car details
    // used in CarList.jsx
    
    return (

        <div className="max-w-xs bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-500 dark:border-gray-500">
            <a href="#">
                <img className="rounded-t-lg" src={imagesrc} alt="" />
            </a>

            <div className="float-right p-2">
                {active ? (
                    <span className="inline-block px-2 py-1 text-xs font-semibold text-orange-700 bg-orange-200 rounded-full">Active</span>
                ) : (
                    <span className="inline-block px-2 py-1 text-xs font-semibold text-gray-700 bg-gray-200 rounded-full">Inactive</span>
                )}

            </div>
            <div className="p-5">
                <a href="#">
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{title}</h5>
                </a>
                 <div className="grid grid-cols-2 gap-2">
                    <div className="col-span-1">
                        <span className="">Licence plate</span>
                    </div>
                    <div className="col-span-1">
                        <span className="">{license_plate}</span>
                    </div>

                </div>
               <LinkButton text="Show details" linkurl={linkurl} />

            </div>
        </div>

    )
}

export default Card
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CarBg from "../../assets/images/bg/car-bg.jpg";
import LogoImage from "../../assets/images/logo-ridetracker.png";
import Switcher from "../../component/Switcher";
import axiosInstance from "../../utils/axiosInstance";
import { useParams } from 'react-router-dom';
import { useSelector } from "react-redux";


const ActivateSucessMessage = () => {
    const [remainingTime, setRemainingTime] = useState(5); // initial time set to 5 seconds

    useEffect(() => {
        const countdownInterval = setInterval(() => {
            setRemainingTime(prevTime => {
                if (prevTime <= 1) {
                    clearInterval(countdownInterval);
                    window.location.href = '/login';
                }
                return prevTime - 1;
            });
        }, 1000);

        // Cleanup interval on component unmount
        return () => {
            clearInterval(countdownInterval);
        };
    }, []);

    return (
        <div className="">
            <h5 className="mb-8 text-xl dark:text-white font-semibold text-center">Activate success</h5>
            <div className="ltr:text-left rtl:text-right">
                <div className="grid grid-cols-1">
                    <p>Account activated successfully, you can now login</p>
                    <p>You will be redirected in {remainingTime} seconds</p>
                </div>
            </div>
        </div>
    );
};



const ActivateConfirm = ({ handleConfirm }) => {
    const isLoading = useSelector(state => state.api.isLoading)

    return (
        <div className="">
            <h5 className="mb-8 text-xl dark:text-white font-semibold text-center">Activate</h5>
            <div className="ltr:text-left rtl:text-right" >
                <div className="grid grid-cols-1">
                    <p>Please confirm you want to activate your account</p>

                    <div className="mb-4">
                        {isLoading ?
                            <button
                                type="button"
                                className="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md w-full h-11 flex items-center justify-center"
                                disabled
                            >
                                <svg className="mr-3 h-5 w-5 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                <span className="font-medium"> Processing... </span>
                            </button>
                            :
                            <button
                                type="button"
                                onClick={handleConfirm}
                                className="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md w-full"
                            >
                                Confirm
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )

}

function Activate() {
    const { uid, token } = useParams();
    const [errors, setErrors] = useState({});

    const [activateComplete, setActivateComplete] = useState(false);

    const handleConfirm = (e) => {
        e.preventDefault();

        const formData = {
            uid: uid,
            token: token
        }

        axiosInstance.post(`/api/v1/auth/users/activation/`, formData)
            .then(res => {
                setActivateComplete(true);
                // set timeout to redirect to login page after 5 seconds
               
            })
            .catch(err => {
                console.log(err)
                if (err.response.status == 500) {
                    setErrors({ "error": "Internal server error" })
                }
                if (err.response.status == 400) {
                    // get error from response.data the field changes depending on the error
                    const newErrors = {};

                    for (const field in err.response.data) {
                        if (err.response.data.hasOwnProperty(field)) {
                            newErrors[field] = err.response.data[field];
                        }
                    }

                    setErrors(newErrors);

                }
            }
            )
    }

    return (
        <>
            <section
                style={{ backgroundImage: `url(${CarBg})` }}
                className="position-relative bg-center bg-cover">
                <div className="absolute inset-0 bg-black opacity-90"></div>
                <div className="container-fluid relative">
                    <div className="grid grid-cols-1">
                        <div className="lg:col-span-4">
                            <div className="flex flex-col min-h-screen md:px-12 py-12 px-3">


                                <div className=" my-auto">
                                    <div className="mt-5 w-full max-w-sm m-auto px-6 py-8 bg-white dark:bg-slate-900 rounded-md shadow-lg shadow-slate-500 dark:shadow-slate-800">
                                        <div className="grid grid-cols-1">

                                            <div className="text-center mx-auto">
                                                <Link to="/index"><img src={LogoImage} alt="" className="h-10" /></Link>
                                            </div>
                                            {
                                                activateComplete ?
                                                    <ActivateSucessMessage />
                                                    :
                                                    <ActivateConfirm handleConfirm={handleConfirm} />
                                            }


                                            {Object.keys(errors).length > 0 &&
                                                <div className="mb-4">
                                                    <div className="text-red-500">
                                                        {Object.keys(errors).map((key, index) => (
                                                            <p key={index}>{errors[key]}</p>
                                                        ))}
                                                    </div>
                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Start Footer --> */}
                                <div className="text-center">
                                    <p className="text-gray-400">© {(new Date().getFullYear())}{" "} Ridetracker. Track and manage your rides <i className="mdi mdi-pin text-orange-700"></i></p>
                                </div>
                                {/* <!-- End Footer --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            {/* <!-- LTR & RTL Mode Code --> */}
            {/* Switcher section */}
            <Switcher />
        </>
    )
}

export default Activate
import React, { useRef } from 'react'
import Input from '../../component/formfields/Input'
import { useDispatch } from 'react-redux'
import { addAddress } from '../../redux/actions/address'
import BasePage from '../../component/layout/BasePage'
import SubmitButton from '../../component/buttons/SubmitButton'
import ReturnButton from '../../component/buttons/ReturnButton'
import Label from '../../component/formfields/Label'


function AddressCreate() {
    // page component for adding a new address
    // renders a form with fields for name, street, number, zipcode, 
    // city, province, country, latitude and longitude

    const dispatch = useDispatch()
    const [address, setAddress] = React.useState({
        name: '',
        street: '',
        number_and_additive: '',
        zip_code: '',
        city: '',
        country: '',
    })

    // const pasteRef = useRef(false);

    const handleInputChange = (event) => {
        // if (!pasteRef.current) {
            const { name, value } = event.target;
            setAddress(prevState => ({
                ...prevState,
                [name]: value
            }));
        // }
        // pasteRef.current = false; // Reset the pasteRef
    };


    // handle form submit, dispatch form data to api
    // on success, redirect to addressbook
    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(addAddress(address))
            .then(res => {
                window.location.href = '/addressbook'
            })
            .catch(err => {
                console.log(err)
            })
    }



    return (
        <BasePage>
            <section className="relative  active" id="features">
                <div className="flex flex-col">
                    <div className="d-flex justify-content-between align-items-center w-full">
                        <div>
                            <h1 className="text-4xl font-bold">Add address</h1>
                        </div>
                        <div className="float-right">
                        </div>
                    </div>

                    <div className="p-1.5 w-full inline-block align-middle">
                        <form onSubmit={e => handleSubmit(e)} >
                            <div className="flex flex-wrap -mx-3 mb-6">

                                {/*  name */}
                                <div className="w-full px-3 mb-6 md:mb-0">
                                    <Input
                                        name="name"
                                        placeholder='Address name'
                                        label="Address Name"
                                        onChangeHandler={handleInputChange}
                                        value={address.name}
                                        required
                                        text="This name will be shown in all ride overviews, menu's and dropdowns" />
                                </div>

                                {/* Street  */}
                                <div className="w-full md:w-1/2 px-3">

                                <Label text="Street and number" required={true} />
                                    <input
                                        type="text"
                                        name="street_and_number"
                                        label="Street and number"
                                        placeholder='Street and number'
                                        text=""
                                        className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-800"
                                        onChange={handleInputChange}
                                        value={address.street_and_number}
                                        // onPaste={addressPaste}
                                    />

                        
                                </div>

                                {/* Zipcode */}
                                <div className="w-full md:w-1/6 px-3">
                                    <Label text="Zipcode" required={true} />
                                    <input
                                        type="text"
                                        name="zip_code"
                                        label="Zipcode"

                                        placeholder='Zipcode 0000AA'
                                        text=""
                                        className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-800"
                                        onChange={handleInputChange}
                                        value={address.zip_code}
                                        // onPaste={addressPaste}
                                    />

                                    {/* <Input name="zip_code" label="Zipcode" text="" onChangeHandler={handleInputChange} required={true} value={address.zip_code} /> */}
                                </div>

                                {/* city */}
                                <div className="w-full md:w-1/3 px-3">
                                    <Label text="City" required={true} />
                                    <input
                                        type="text"
                                        name="city"
                                        label="City"
                                        placeholder='City'
                                        text=""
                                        onChange={handleInputChange}
                                        value={address.city}
                                        // onPaste={addressPaste}
                                        className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-800"
                                    />

                                    {/* <Input name="city" label="City" text="" onChangeHandler={handleInputChange} required={true} value={address.city} /> */}
                                </div>


                                {/* country */}
                                <div className="w-full md:w-1/3 px-3">
                                    <Input name="country" label="Country" text="" onChangeHandler={handleInputChange} value={address.country} />
                                </div>

                                {/* lattitude */}
                                <div className="w-full md:w-1/3 px-3">
                                    <Label text="Latitude" required={false} />
                                    <input
                                        type="text"
                                        name="latitude"
                                        label="Latitude"
                                        placeholder='Latitude'
                                        text=""
                                        className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-800"
                                        onChange={handleInputChange}
                                        value={address.latitude}
                                        // onPaste={handlePaste}
                                    />

                                </div>

                                {/* longitude */}
                                <div className="w-full md:w-1/3 px-3">

                                    <Label text="Longitude" required={false} />
                                    <input
                                        type="text"
                                        name="longitude"
                                        label="Longitude"
                                        placeholder='Longitude'
                                        text=""
                                        onChange={handleInputChange}
                                        value={address.longitude}
                                        // onPaste={handlePaste}
                                        className="appearance-none block w-full text-gray-700 border border-gray-300 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-800"
                                    />


                                </div>
                            </div>
                            <div className="flex items-center justify-center mt-12">
                                <SubmitButton text="Save" />

                                <ReturnButton linkurl="/addressbook" text="Cancel" />

                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </BasePage>


    )
}

export default AddressCreate
import { useState, useRef } from 'react';




function UploadField({ setUploadedImage, setPreviewURL }) {
    const [dragActive, setDragActive] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const inputRef = useRef(null);

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFile(e.dataTransfer.files);
        }
    };

    const handleChange = (e) => {
        e.preventDefault();

        if (e.target.files && e.target.files[0]) {
            handleFile(e.target.files);
        }
    };

    const handleFile = (files) => {

        const file = files[0];
        if (file && isImage(file)) {
            setSelectedFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewURL(reader.result);
            };
            reader.readAsDataURL(file);
            setUploadedImage(file);
        } else {
            setSelectedFile(null);
            setPreviewURL('/path/to/default-image.png');
            setUploadedImage(null);
        }
    };

    const isImage = (file) => {
        const acceptedImageTypes = ['image/png', 'image/jpeg', 'image/jpg'];
        return acceptedImageTypes.includes(file.type);
    };

    const onButtonClick = () => {
        inputRef.current.click();
    };

    return (
        // <form className="max-w-full"   onSubmit={(e) => e.preventDefault()}>
        <div className="flex" onDragEnter={handleDrag}>
            <input ref={inputRef} type="file" className="hidden" id="input-file-upload" multiple={false} onChange={handleChange} />

            <label
                className={`h-64 w-96 max-w-full text-center relative ${dragActive ? 'bg-white' : 'bg-gray-100'
                    } border-2 border-dashed rounded-lg border-gray-300`}
                htmlFor="input-file-upload"
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
            >
                <button
                    className="h-full w-full flex flex-col items-center justify-center"
                    onClick={onButtonClick}
                    type='button'
                >
                    <p>Drag and drop your file here or</p>
                    <span className="mt-2 px-4 py-2 text-base border-none font-semibold text-gray-600 hover:underline focus:outline-none">
                        Upload a file
                    </span>
                </button>
            </label>
            {dragActive && (
                <div
                    id="drag-file-element"
                    className="absolute inset-0 rounded-lg"
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                ></div>
            )}

            {selectedFile && <p>Selected file: {selectedFile.name}</p>}
        </div>
        // </form>
    );
}

export default UploadField;

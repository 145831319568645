import React from 'react'
import BasePage from '../component/layout/BasePage'
import UploadExplainer from '../component/UploadExplainer'


function HowItWorks() {
  return (
    <BasePage>
      <UploadExplainer />
    </BasePage>
  )
}

export default HowItWorks
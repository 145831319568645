import React from 'react'
import BasePage from '../component/layout/BasePage'
import Pricing from '../component/Pricing'



function PricingPage() {
  return (
    <BasePage>
      <Pricing />
    </BasePage>
  )
}

export default PricingPage
import React, { useState } from "react";
import { Link } from "react-router-dom";
import CarBg from "../../assets/images/bg/car-bg.jpg";
import LogoImage from "../../assets/images/logo-ridetracker.png";
import Switcher from "../../component/Switcher";
import axiosInstance from "../../utils/axiosInstance";
import Input from "../../component/formfields/Input";
import SubmitButton from "../../component/buttons/SubmitButton";
import { useSelector } from "react-redux";


const SignupSuccess = () => {
    return (
        <div className="">
            <h5 className="mb-8 text-xl dark:text-white font-semibold text-center">Registration succes</h5>
            <div className="ltr:text-left rtl:text-right" >
                <div className="grid grid-cols-1">

                    <p>Please check your email for an activation link</p>
                </div>
            </div>
        </div>
    )
}


export default function Signup() {

    const [formData, setFormData] = useState({})
    const [errors, setErrors] = useState({});
    const [activateComplete, setActivateComplete] = useState(false)
    const isLoading = useSelector(state => state.api.isLoading)

    const handleSubmit = (e) => {
        e.preventDefault();
        axiosInstance.post(`/api/v1/auth/users/`, formData)
            .then(res => {

                setActivateComplete(true)

            })
            .catch(err => {
                console.log(err)
                if (err.response.status == 500) {
                    setErrors({ "error": "Internal server error" })
                }
                if (err.response.status == 400) {
                    // get error from response.data the field changes depending on the error
                    const newErrors = {};

                    for (const field in err.response.data) {
                        if (err.response.data.hasOwnProperty(field)) {
                            newErrors[field] = err.response.data[field];
                        }
                    }
                    setErrors(newErrors);
                }
            })
    }

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    return (
        <>
            <section
                style={{ backgroundImage: `url(${CarBg})` }}
                className="position-relative bg-center bg-cover">
                <div className="absolute inset-0 bg-black opacity-90"></div>
                <div className="container-fluid relative">
                    <div className="grid grid-cols-1">
                        <div className="lg:col-span-4">
                            <div className="flex flex-col min-h-screen md:px-12 py-12 px-3">


                                <div className=" my-auto">
                                    <div className="mt-5 w-full max-w-sm m-auto px-6 py-8 bg-white dark:bg-slate-900 rounded-md shadow-lg shadow-slate-500 dark:shadow-slate-800">
                                        <div className="grid grid-cols-1">

                                            <div className="text-center mx-auto">
                                                <Link to="/index"><img src={LogoImage} alt="" className="h-10" /></Link>
                                            </div>
                                            {
                                                activateComplete ?
                                                    <SignupSuccess />
                                                    :

                                                    <div className="">
                                                        <h5 className="mb-8 text-xl dark:text-white font-semibold text-center">Signup</h5>
                                                        <form className="ltr:text-left rtl:text-right" onSubmit={handleSubmit}>
                                                            <div className="grid grid-cols-1">
                                                                <div className="mb-4">
                                                                    <Input name="email" label="Email" placeholder="Enter email" required onChangeHandler={handleInputChange} value={formData.email} />
                                                                </div>
                                                                <div className="mb-4">
                                                                    <Input name="password" type="password" label="Password" placeholder="Enter password" required onChangeHandler={handleInputChange} value={formData.password} />
                                                                </div>
                                                                <div className="mb-4">
                                                                    <Input name="re_password" type="password" label="Verify password" placeholder="Enter password" required onChangeHandler={handleInputChange} value={formData.re_password} />
                                                                </div>


                                                                <div className="mb-4">
                                                                    <SubmitButton text="Signup" isLoading={isLoading} width="w-full" />
                                                                </div>
                                                                {Object.keys(errors).length > 0 && (
                                                                    <div className="text-red-500 text-center">
                                                                        {Object.entries(errors).map(([field, message]) => (
                                                                            <div key={field}>
                                                                                {message}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                )}

                                                                <div className="text-center">
                                                                    <span className="text-slate-400 dark:text-slate-300 me-2">Already have an account ?</span>
                                                                    <Link to="/login" className="text-orange-600 hover:text-dark dark:text-white dark:hover:text-orange-600">Sign in</Link>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Start Footer --> */}
                                <div className="text-center">
                                    <p className="text-gray-400">© {(new Date().getFullYear())}{" "} Ridetracker. Track and manage your rides <i className="mdi mdi-pin text-orange-700"></i></p>
                                </div>
                                {/* <!-- End Footer --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- LTR & RTL Mode Code --> */}
            {/* Switcher section */}
            <Switcher />
        </>
    )
}

import React from 'react'

function SubmitButton({ text, isLoading, width }) {

    if (isLoading) {
        return (
            <button type="button"
                className={`btn w-36 bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md h-11 justify-center flex items-center ${width}`}
                disabled >
                <svg className="mr-3 h-5 w-5 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <span className="font-medium"> Processing... </span>
            </button>
        )
    } else {
        return (
            <button
                type="submit"
                id="submit"
                name="submit"
                className={`btn w-36 bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md h-11 justify-center flex items-center ${width}`}
            >
                {text}
            </button>

        )
    }

}

export default SubmitButton
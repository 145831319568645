import React from 'react'
import DeleteButton from '../../../component/buttons/DeleteButton'
import { FaCheck, FaTimes } from 'react-icons/fa'
import { deleteCar } from '../../../redux/actions/cars'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import DeleteConfirmButton from '../../../component/buttons/DeleteConfirmButton'

function CardetailSection({ carData }) {
    // section to display car details, used in CarDetail.jsx
    // carData is an object with car details
    // replaced by editform in CarDetail.jsx if editMode is true

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // date options for date formatting
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' };

    // delete function to delete car
    // dispatches deleteCar action, then navigates to /cars
    const handleDelete = () => {

        dispatch(deleteCar(carData.id))
            .then(res => {
                navigate('/cars')
            }
            )
            .catch(
                err => {
                    console.log(err);
                }
            )
    }

    return (

        <div className="mt-6 border-t border-gray-100">


            <dl className="divide-y divide-gray-100">
                <div className="px-2 py-4 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0">
                    {carData.image && (
                        <img src={carData.image} alt="Car" className="w-full h-auto" />
                    )}
                </div>

                <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Name:</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {carData.name}
                    </dd>
                </div>

                <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">License plate:</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {carData.license_plate}
                    </dd>
                </div>
                <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Active:</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">

                        <span className="whitespace-nowrap">
                            {carData.active ? (
                                <FaCheck className="text-green" />
                            ) : (

                                <FaTimes className="text-red" />
                            )}
                        </span>
                    </dd>
                </div>

                <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Created:</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {carData.created_at && new Intl.DateTimeFormat('en-EU', options).format(new Date(carData.created_at))}
                    </dd>
                </div>
                <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Updated:</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        {carData.updated_at && new Intl.DateTimeFormat('en-EU', options).format(new Date(carData.updated_at))}
                    </dd>
                </div>
                <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                    <dt className="text-sm font-medium leading-6 text-gray-900">Delete:</dt>
                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        <div className="flex">

                            <div className="flex-col">
                                <DeleteConfirmButton text="Delete" id={carData.id} deleteHandler={handleDelete} />
                            </div>

                        </div>
                    </dd>
                </div>
            </dl>
        </div>
    )
}

export default CardetailSection
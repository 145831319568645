
import React from 'react';
import BaseTable from '../../../component/tables/BaseTable';

function MembersTable({ data }) {
  // table definition for company members list, used in CompanyDetail.jsx


  const columns = [
    {
      header: 'Name',
      accessor: 'full_name',
    },
    {
      header: 'Email',
      accessor: 'email',
    },
    {
      header: 'Role',
      accessor: 'role',
    },
   
   
  ];

  return <BaseTable data={data} columns={columns} />;
}

export default MembersTable;

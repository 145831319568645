import React, { useEffect, useState } from 'react'
import BasePage from '../../component/layout/BasePage';
import ProfileInfo from './components/ProfileDetails';
import ProfileEditForm from './components/ProfileEditForm';
import Toggle from '../../component/formfields/Toggle';
import axiosInstance from '../../utils/axiosInstance';

function ProfileSelf() {
    const [editMode, setEditMode] = useState(false);
    const [data, setData] = useState([])

    
    useEffect(() => {
        // fetch user data
        axiosInstance.get('/api/v1/profile/me/')
            .then(res => {
                setData(res.data)
             
            })
            .catch(err => {
                console.log(err)
            })

    }, [])



    return (
        <BasePage>

            <div className='container'>
                <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">Profile</h3>
                </div>
                {/* right aligned div */}
                <div className="flex justify-end">
                    <Toggle
                        checked={editMode}
                        onChangeHandler={() => setEditMode(currentstate => !currentstate)}
                        label="Edit mode"
                    />
                </div>
                {editMode ? (
                    <ProfileEditForm data={data} setData={setData} setEditMode={setEditMode}/>
                ) : (
                    <ProfileInfo data={data} />
                )}

            </div>
        </BasePage>
    )
}

export default ProfileSelf
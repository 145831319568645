import React, { useEffect, useState } from 'react'
import BasePage from '../../component/layout/BasePage';
import ProfileInfo from './components/ProfileDetails';
import axiosInstance from '../../utils/axiosInstance';

import { useParams } from 'react-router-dom'


const PrivateProfile = () => {
    return (

        <div className="container mx-auto">
            <div className="flex flex-col items-center justify-center h-screen">
                
                <h2 className="text-2xl font-semibold text-gray-800">Private profile</h2>
                <p className="text-gray-700">This profile is private</p>
            </div>
        </div>

    )
}


function Profile() {
    const { id } = useParams()
    const [data, setData] = useState([])
    const [privateProfile, setPrivateProfile] = useState(false)


    useEffect(() => {
        // fetch user data from id in url
        axiosInstance.get(`/api/v1/profile/${id}/`)
            .then(res => {
                setData(res.data)

            })
            .catch(err => {

                console.log(err)

                if (err.response.status === 403) {
                    setPrivateProfile(true)
                }
            })

    }, [])



    return (
        <BasePage>

            <div className='container'>
                <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">Profile other</h3>
                </div>

                {privateProfile ? (
                    <PrivateProfile />
                ) : (

                    <ProfileInfo data={data} />
                )}
            </div>
        </BasePage>
    )
}

export default Profile
// simple delete button with confirm modal 
// renders a delete button when clicked opens a modal with confirm delete button
// on confirm delete button click the deleteHandler is called with the id of the item to delete

import React from 'react'
import Modal from 'react-modal';
import { FaTrash } from "react-icons/fa";
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
      
    },
};

function DeleteConfirmButton(props) {
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    // handle confirm delete
    const handleDelete = () => {
        // delete company
        // close modal
        closeModal()
        props.deleteHandler(props.id)

    }

    return (
        <div >
            <button
                type="button"
                onClick={openModal}
                className="btn text-fireengine hover:bg-fireengine hover:text-white rounded-md hover:shadow-md cursor-pointer flex items-center justify-center"
            >
                <FaTrash className="mr-2" />
                Delete
            </button>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Delete Modal"
                ariaHideApp={false}
         
            >
                <div className="mb-2">
                    <h2>Are you sure you want to delete?</h2>
                </div>
                <div className="flex justify-center">
                    <button
                        onClick={closeModal}
                        className="bg-gray-300 hover:bg-gray-400 text-white px-4 py-2 rounded hover:bg-opacity-90 focus:outline-none mx-2"
                    >
                        Cancel
                    </button>
   
                    <button
                        onClick={() => handleDelete()}
                        className="bg-orange-500 hover:bg-orange-700 text-white px-4 py-2 rounded hover:bg-opacity-90 focus:outline-none mx-2 dark:text-white dark:bg-dark"
                    >
                        Confirm
                    </button>
                    
                </div>
            </Modal>
        </div>

    )
}


export default DeleteConfirmButton
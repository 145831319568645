import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";

import CarBg from "../../assets/images/bg/car-bg.jpg";
import LogoImage from "../../assets/images/logo-ridetracker.png";
import Switcher from "../../component/Switcher";
import axiosInstance from "../../utils/axiosInstance";
import Input from "../../component/formfields/Input";
import SubmitButton from "../../component/buttons/SubmitButton";
import { useParams } from 'react-router-dom';

const ActivateSucessMessage = () => {
    const [remainingTime, setRemainingTime] = useState(5); // initial time set to 5 seconds

    useEffect(() => {
        const countdownInterval = setInterval(() => {
            setRemainingTime(prevTime => {
                if (prevTime <= 1) {
                    clearInterval(countdownInterval);
                    window.location.href = '/login';
                }
                return prevTime - 1;
            });
        }, 1000);

        // Cleanup interval on component unmount
        return () => {
            clearInterval(countdownInterval);
        };
    }, []);

    return (
        <div className="">
            <h5 className="mb-8 text-xl dark:text-white font-semibold text-center">Reset success</h5>
            <div className="ltr:text-left rtl:text-right">
                <div className="grid grid-cols-1">
                    <p>Password reset successfully, you can now login with new credentials</p>
                    <p>You will be redirected in {remainingTime} seconds</p>
                </div>
            </div>
        </div>
    );
};




export default function ResetPassword() {
    const { uid, token } = useParams(); // get uid and token from url params
    
    const [formData, setFormData] = useState({
        password: '', 
        re_password: '',
    });

    const [isLoading, setIsLoading] = useState(false);
    const [resetComplete, setResetComplete] = useState(false);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        
       

        const postData = {
            uid: uid,                   
            token: token,
            new_password: formData.password,
            re_new_password: formData.re_password
        }

        axiosInstance.post(`/api/v1/auth/users/reset_password_confirm/`, postData)
            .then(res => {
                setIsLoading(false);
                setResetComplete(true);
                console.log(res)
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false);
            })

    }


    return (
        <>
            <section
                style={{ backgroundImage: `url(${CarBg})` }}
                className="position-relative bg-center bg-cover">
                <div className="absolute inset-0 bg-black opacity-90"></div>
                <div className="container-fluid relative">
                    <div className="grid grid-cols-1">
                        <div className="lg:col-span-4">
                            <div className="flex flex-col min-h-screen md:px-12 py-12 px-3">
                                <div className=" my-auto">
                                    <div className="mt-5 w-full max-w-sm m-auto px-6 py-8 bg-white dark:bg-slate-900 rounded-md shadow-lg shadow-slate-500 dark:shadow-slate-800">
                                        <div className="grid grid-cols-1">
                                            <div className="text-center mx-auto mb-5">
                                                <Link to="/index"><img src={LogoImage} alt="" className="h-10" /></Link>
                                            </div>

                                            {!resetComplete ?
                                                <div className="">
                                                    <h5 className="mb-8 text-xl dark:text-white font-semibold text-center">Reset Your Password</h5>
                                                    <p className="text-slate-400 dark:text-slate-300 mb-6 text-center">Enter a new password</p>
                                                    <form className="ltr:text-left rtl:text-right" onSubmit={handleSubmit}>
                                                        <div className="grid grid-cols-1">
                                                            {/* password */}
                                                            <div className="mb-4">
                                                                <Input name="password" label="Password"type="password" required onChangeHandler={handleInputChange} value={formData.password} />
                                                            </div>

                                                            {/* re_password */}
                                                            <div className="mb-4">
                                                                <Input name="re_password" label="Confirm password" type="password" required onChangeHandler={handleInputChange} value={formData.re_password} />
                                                            </div>
                                                            
                                                            <div className="mb-4">
                                                                <SubmitButton text="Submit" isLoading={isLoading} width='w-full'/>
                                                            </div>

                                                        </div>
                                                    </form>
                                                </div>
                                                :
                                               <ActivateSucessMessage />
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Start Footer --> */}
                                <div className="text-center">
                                    <p className="text-gray-400">© {(new Date().getFullYear())}{" "} Ridetracker. Track and manage your rides <i className="mdi mdi-pin text-orange-700"></i></p>

                                </div>
                                {/* <!-- End Footer --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            {/* Switcher section */}
            < Switcher />
        </>
    )
}
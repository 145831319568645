import React, { useEffect, useState } from 'react'

import { editAddress } from '../../../redux/actions/address'
import { useDispatch } from 'react-redux';
import Input from '../../../component/formfields/Input';
import { useParams } from 'react-router-dom';

function AddressEditForm({ addressData, setAddressData, setEditMode }) {
    // section to render an update form, used in AddressDetail.jsx on editMode=true
    // addressData is an object with address details to pre-fill the form

    const { addressId } = useParams();
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({});

    // dispatch form data to api, on success set addressData to new data
    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(editAddress(addressId, formData))
            .then(res => {
                setAddressData(res);
                setEditMode(false);
            })
            .catch(err => {
                console.log(err);
            })
    }

    // set form data to address data, so that form is pre-filled with address data
    useEffect(() => {
        setFormData(
            {
                name: addressData.name,
                street_and_number: addressData.street_and_number,
                zip_code: addressData.zip_code,
                city: addressData.city,
                province: addressData.province,
                country: addressData.country,
                latitude: addressData.latitude,
                longitude: addressData.longitude,
            }
        )
    }, [addressData]);

    // helper function to handle input change
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }


    return (
        <form onSubmit={e => handleSubmit(e)}>
            <div className="mt-6 border-t border-gray-100">
                <dl className="divide-y divide-gray-100">
                    <div className="px-2 pt-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Name:</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <Input
                                name="name"
                                labelDisabled={true}
                                label="Name"
                                required onChangeHandler={handleInputChange}
                                value={formData.name}
                            />
                        </dd>
                    </div>

                    <div className="px-2 pt-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Zip code</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <Input
                                name="street_and_number"
                                labelDisabled={true}
                                label="Street"
                                required
                                onChangeHandler={handleInputChange}
                                value={formData.street_and_number}
                            />


                        </dd>
                    </div>
                    <div className="px-2 pt-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Zip code</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <Input
                                name="zip_code"
                                labelDisabled={true}
                                label="Zip code"
                                required
                                onChangeHandler={handleInputChange}
                                value={formData.zip_code}
                            />
                        </dd>
                    </div>

                    <div className="px-2 pt-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">City</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <Input
                                name="city"
                                labelDisabled={true}
                                label="City"
                                required
                                onChangeHandler={handleInputChange}
                                value={formData.city}
                            />
                        </dd>
                    </div>


                    <div className="px-2 pt-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Country</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <Input
                                name="country"
                                labelDisabled={true}
                                label="Country"
                                required
                                onChangeHandler={handleInputChange}
                                value={formData.country}
                            />
                        </dd>
                    </div>
                    <div className="px-2 pt-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Coordinates:</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <span className="whitespace-nowrap">
                                <div className="flex w-full gap-4">
                                    <div className="w-1/2">
                                        <Input
                                            name="latitude"
                                            labelDisabled={true}
                                            label="Latitude"
                                            onChangeHandler={handleInputChange}
                                            value={formData.latitude}
                                        />
                                    </div>
                                    <div className="w-1/2">
                                        <Input
                                            name="longitude"
                                            labelDisabled={true}
                                            label="Longitude"
                                            onChangeHandler={handleInputChange}
                                            value={formData.longitude}
                                        />
                                    </div>

                                </div>
                            </span>
                        </dd>
                    </div>
                    <div className="px-2 py-4 ">
                        <div className="flex items-center justify-center mt-2">
                            <button
                                type="submit"
                                id="submit"
                                name="submit"
                                className="btn bg-orange-500 hover:bg-orange-500 border-orange-600 hover:border-orange-500 text-white rounded-md h-11 justify-center flex items-center"
                            >
                                Save
                            </button>
                        </div>
                    </div>

                </dl >
            </div >
        </form>
    )
}

export default AddressEditForm
import React from 'react'
import BasePage from '../component/layout/BasePage'
import Contact from '../component/Contact'


function ContactPage() {

    return (
        <BasePage>
            {/* Start */}
           <Contact />
        </BasePage >
    )
}

export default ContactPage
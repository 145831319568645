import React, { useEffect } from 'react';
import BasePage from '../../component/layout/BasePage';
import RideEditForm from './components/RideEditForm';
import RidedetailSection from './components/RidedetailSection';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Toggle from '../../component/formfields/Toggle'
import axiosInstance from '../../utils/axiosInstance';


function RideDetail() {
    const { rideId } = useParams()

    const [data, setData] = useState([]);
    const [inputChanged, setInputChanged] = useState(false) // to check if input has been changed
    const [editMode, setEditMode] = useState(false);
    const toggleEditMode = () => {
        if (inputChanged) {
            // if input has been changed, ask user if they want to leave the page
            if (window.confirm('You have unsaved changes. Do you want to leave?')) {
                setEditMode(currentstate => !currentstate)
                setInputChanged(false)
            }
        } else {
            setEditMode(currentstate => !currentstate)
        }
      
    }

    useEffect(() => {
        axiosInstance.get(`/api/rides/${rideId}/`)
            .then(res => {
                
                setData(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [rideId]);


    return (
        <BasePage>
            <div className='container'>
                <div className="px-4 sm:px-0">
                    <h3 className="text-base font-semibold leading-7 text-gray-900">Ride detail</h3>
                </div>

                {/* right aligned div */}
                <div className="flex justify-end">
                    <Toggle
                        checked={editMode}
                        onChangeHandler={toggleEditMode}
                        label="Edit mode"
                    />
                </div>
                {editMode ? (
                    <RideEditForm data={data} setData={setData} setEditMode={setEditMode} setInputChanged={setInputChanged}/>
                ) : (
                    <RidedetailSection data={data} />
                )}


            </div>
        </BasePage>


    )
}

export default RideDetail
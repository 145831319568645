import React, { useState } from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';

const InfoIcon = ({ text }) => {
    const [showInfo, setShowInfo] = useState(false);

    return (
        <div className="relative inline-block">
            <AiOutlineInfoCircle 
                className="ml-2 text-orange-500 cursor-pointer"
                onMouseEnter={() => setShowInfo(true)}
                onMouseLeave={() => setShowInfo(false)}
            />
            {showInfo && (
                <div className="absolute left-0 mt-2 w-64 p-2 bg-white border border-gray-300 rounded shadow-md z-10">
                    {text}
                </div>
            )}
        </div>
    );
};

export default InfoIcon;
import React, { useRef, useState, useEffect } from 'react';
import Modal from 'react-modal';
import Input from '../../../component/formfields/Input';
import SubmitButton from '../../../component/buttons/SubmitButton';
import { useSelector, useDispatch } from 'react-redux';
import axiosInstance from '../../../utils/axiosInstance';
import { createToast } from '../../../redux/actions/toast';
import { set } from 'date-fns';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

Modal.setAppElement('#root');



function CompanyInviteModal() {
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.api.isLoading)
    const user = useSelector(state => state.auth.user)

    const subtitleRef = useRef(null);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [inviteComplete, setInviteComplete] = useState(false)
    const [remainingTime, setRemainingTime] = useState(5);

    useEffect(() => {

        if (inviteComplete) {
            const countdownInterval = setInterval(() => {
                setRemainingTime(prevTime => {
                    if (prevTime <= 1) {
                        clearInterval(countdownInterval);
                        setIsOpen(false);
                    }
                    return prevTime - 1;
                });
            }, 1000);

            return () => {
                clearInterval(countdownInterval);
            };
        }
    }, [inviteComplete]);


    function openModal() {
        setIsOpen(true);
        setInviteComplete(false);
    }

    function afterOpenModal() {
        if (subtitleRef.current) {
            subtitleRef.current.style.color = '#f00';
        }
    }

    function closeModal() {
        setIsOpen(false);
        setInviteComplete(false);
        setRemainingTime(5);


    }

    const [formData, setFormData] = React.useState({
        email: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const postData = {
            ...formData,
            company_id: user.company
        }

        try {
            await axiosInstance.post(`/api/v1/auth/invite/`, postData)
            closeModal();
            dispatch(createToast('Invite sent', 'success'));
        } catch (error) {
            console.error('There was an error submitting the form!', error);
            dispatch(createToast('Error sending invite', 'error'));
        }

        setInviteComplete(true);
    }



    return (
        <div>
            <button onClick={openModal} className="btn bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md py-2 ">
                Invite a new member
            </button>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Invite Modal"
            >
                <section className="flex" >
                    <div className="flex-col float-right p-2">
                        <button
                            onClick={closeModal}
                            className="text-red hover:text-gray-700 focus:outline-none p-1 absolute top-2 right-2"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6"
                                viewBox="0 0 20 20"
                                fill="none"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M6 6L14 14M14 6L6 14"
                                />
                            </svg>
                        </button>
                        <div className="container lg mx-auto">
                            <h2 className='text-gray-900 font-bold mb-4 '>Invite a new member</h2>




                            <div ref={subtitleRef}>

                                <form className="ltr:text-left rtl:text-right" onSubmit={handleSubmit}>
                                    {!inviteComplete ?

                                        <div className="">
                                            <div className="grid grid-cols-1">
                                                <Input name="email" label="Email" placeholder="Enter email" required onChangeHandler={handleInputChange} value={formData.email} />
                                            </div>

                                            <div className="mb-4">
                                                <SubmitButton text="Invite" isLoading={isLoading} width="w-full" />
                                            </div>

                                        </div>
                                        :
                                        <div className="text-center">
                                            closing in {remainingTime} seconds
                                        </div>
                                    }


                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </Modal>
        </div>
    );
}

export default CompanyInviteModal


import React from 'react';
import Label from './Label';

function CheckBox({ label, name, checked, onChangeHandler, labelDisabled, required }) {
    const handleCheckBoxChange = (e) => {
        onChangeHandler(e.target.checked);
    };

    return (
        <div className="flex p-4">
            {!labelDisabled &&
                <Label text={label ? label : name} required={required} />
            }
            <input
                className="form-checkbox h-5 w-5 text-red-500 mx-2 my-1"
                name={name}
                id={name}
                type="checkbox"
                checked={checked}
                onChange={handleCheckBoxChange}
            />


        </div>
    );
}

export default CheckBox;

import React from 'react'
import BasePage from '../component/layout/BasePage'

function NotFound404() {
    return (
        <BasePage>
            <div className="container mx-auto">
                <div className="flex flex-col items-center justify-center h-screen">
                    <h1 className="text-9xl font-bold text-gray-900">404</h1>
                    <h2 className="text-2xl font-semibold text-gray-800">Page Not Found</h2>
                    <p className="text-gray-700">The page you are looking for does not exist.</p>
                </div>
            </div>
        </BasePage >
    )
}

export default NotFound404
import React, { useEffect, useState, useMemo } from 'react'
import axiosInstance from '../../utils/axiosInstance';
import BasePage from '../../component/layout/BasePage';
import RideFormUpload from './components/RideFormUpload';

function RideCreate() {
    // page component for adding a new ride
    const [cars, setCars] = useState([]);
    const [addresses, setAddresses] = useState([]);
    const [users, setUsers] = useState([{}]);

    useEffect(() => {
        axiosInstance.get('/api/addresses/')
            .then(res => {
                setAddresses(res.data)
            })
            .catch(err => {
                console.log(err)
            })

        axiosInstance.get(
            "/api/cars/?active=true"
        ).then((res) => {
            setCars(res.data);
        }).catch((err) => {
            console.log(err);
        })  

        axiosInstance.get("/api/v1/auth/users/")
            .then((res) => {
                setUsers(res.data);
            })
            .catch((err) => {
                console.log(err);
            });

    }, [])


    const addressOptions = useMemo(() => (
        // useMemo to generate options for address select dropdown
        addresses.map(item => ({
            value: item.id,
            label: item.name
        }))
    ), [addresses]);

    const carOptions = useMemo(() => (
        // useMemo to generate options for car select dropdown
        cars.map(item => ({
            value: item.id,
            label: item.name
        }))
    ), [cars]);

    const driverOptions = useMemo(() => (
        users.map(item => ({
            value: item.id,
            label: item.email
        }))
    ), [users]);

    return (
        <BasePage >
            <div className="flex flex-col items-center mb-10">
                <div className="container">
                    <h1 className="text-4xl font-bold">Add ride</h1>
                    <RideFormUpload
                        addressOptions={addressOptions}
                        carOptions={carOptions}
                        driverOptions={driverOptions}
                    />
                </div>
            </div>
        </BasePage >
    )
}

export default RideCreate; 

export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = "LOGOUT"
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS"
export const SET_AUTHENTICATED = "SET_AUTHENTICATED"


// cars
export const LOAD_CARS = 'LOAD_CARS';
export const SET_CARS_LIST = 'SET_CARS_LIST';
export const SET_CAR = 'SET_CAR';
export const ADD_CAR = 'ADD_CAR';
export const DELETE_CAR = 'DELETE_CAR';

// toast
export const SHOW_TOAST = 'SHOW_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';

// api
export const API_CALL_START = 'API_CALL_START';
export const API_CALL_SUCCESS = 'API_CALL_SUCCESS';
export const API_CALL_FAILURE = 'API_CALL_FAILURE';

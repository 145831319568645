import React from 'react'


const DistancePreview = ({ start, end }) => {
    const distance = end - start; // Calculate the distance without taking absolute value

    // Define styles based on distance
    let style = {};
    if (!distance) {
        style.color = 'grey';
    } else if (distance < 0) {
        style.color = 'red';
    } else if (distance >= 1 && distance <= 100) {
        style.color = 'green';
    } else if (distance > 1000) {
        style.color = 'red';
    }

    // Return a div element with the distance and style
    return (
        <div style={style}>
            {distance ? `${distance} km` : "no data"}
        </div>
    );
};

export default DistancePreview
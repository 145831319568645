import React, {useState} from "react";
import { Link } from "react-router-dom";

import CarBg from "../../assets/images/bg/car-bg.jpg";
import LogoImage from "../../assets/images/logo-ridetracker.png";
import Switcher from "../../component/Switcher";
import axiosInstance from "../../utils/axiosInstance";
import Input from "../../component/formfields/Input";
import SubmitButton from "../../component/buttons/SubmitButton";


export default function ResetPassword() {
    const [formData, setFormData] = useState({
        email: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [resetComplete, setResetComplete] = useState(false);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);

        axiosInstance.post(`/api/v1/auth/users/reset_password/`, formData)
            .then(res => {
                setIsLoading(false);
                setResetComplete(true);
                console.log(res)
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false);
            })

    }


    return (
        <>
            <section
                style={{ backgroundImage: `url(${CarBg})` }}
                className="position-relative bg-center bg-cover">
                <div className="absolute inset-0 bg-black opacity-90"></div>
                <div className="container-fluid relative">
                    <div className="grid grid-cols-1">
                        <div className="lg:col-span-4">
                            <div className="flex flex-col min-h-screen md:px-12 py-12 px-3">
                                <div className=" my-auto">
                                    <div className="mt-5 w-full max-w-sm m-auto px-6 py-8 bg-white dark:bg-slate-900 rounded-md shadow-lg shadow-slate-500 dark:shadow-slate-800">
                                        <div className="grid grid-cols-1">
                                            <div className="text-center mx-auto mb-5">
                                                <Link to="/index"><img src={LogoImage} alt="" className="h-10" /></Link>
                                            </div>

                                            {!resetComplete ?
                                                <div className="">
                                                    <h5 className="mb-8 text-xl dark:text-white font-semibold text-center">Reset Your Password</h5>
                                                    <p className="text-slate-400 dark:text-slate-300 mb-6 text-center">Please enter your email address. You will receive a link to create a new password via email.</p>
                                                    <form className="ltr:text-left rtl:text-right" onSubmit={handleSubmit}>
                                                        <div className="grid grid-cols-1">
                                                            <div className="mb-4">
                                                                <Input name="email" label="Email" placeholder="Enter email" required onChangeHandler={handleInputChange} value={formData.email} />
                                                            </div>
                                                            <div className="mb-4">
                                                                <SubmitButton text="Reset password" isLoading={isLoading} width='w-full'/>
                                                            </div>

                                                        </div>
                                                    </form>
                                                </div>
                                                :
                                                <div className="">
                                                    <h5 className="mb-8 text-xl dark:text-white font-semibold text-center">Password reset</h5>
                                                    <p className="text-slate-400 dark:text-slate-300 mb-6 text-center">Please check your email for a reset link</p>

                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Start Footer --> */}
                                <div className="text-center">
                                    <p className="text-gray-400">© {(new Date().getFullYear())}{" "} Ridetracker. Track and manage your rides <i className="mdi mdi-pin text-orange-700"></i></p>

                                </div>
                                {/* <!-- End Footer --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            {/* Switcher section */}
            < Switcher />
        </>
    )
}
import { combineReducers } from "redux";
import auth from './auth';
import toast from './toast';
import api from './api';

const reducers = combineReducers({
  auth,
  toast,
  api
});
export default reducers;
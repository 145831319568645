

import React, { useEffect, useState } from 'react'
import BasePage from '../../component/layout/BasePage'
import MembersTable from './components/MembersTable'
import axiosInstance from '../../utils/axiosInstance'
import CompanyInviteModal from './components/CompanyInviteForm';


function CompanyDetail() {
    // detail page for a single address
    // renders either a AddressDetailSection or AddressEditForm component, depending on editMode state

    const [data, setData] = useState([]);
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        const getAddress = async () => {
            try {
                axiosInstance.get(`/api/v1/company/members/`)
                    .then(res => {
                        setData(res.data)
                    })
            }
            catch (error) {
                console.log(error);
            }
        }
        getAddress();

    }, []);


    return (
        <BasePage>
            <div className='container'>

                <div className="flex justify-between">
                    <div className="px-4 sm:px-0">
                        <h3 className="text-base font-semibold leading-7 text-gray-900">Company members</h3>
                    </div>

                    <CompanyInviteModal />
                    {/* <a href="/company/invite"
                        className="btn w-24 bg-orange-600 hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-white rounded-md cursor-pointer"
                    >
                        Invite
                    </a> */}
                </div>

                <div className="overflow-x-auto">
                    <div className="p-1.5 w-full inline-block align-middle">
                        <div className="overflow-hidden border rounded-lg">
                            <MembersTable data={data} />

                        </div>
                    </div>
                </div>
            </div>
        </BasePage>
    )
}

export default CompanyDetail


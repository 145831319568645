import React, { useState } from 'react'
import BasePage from '../../component/layout/BasePage'
import { useSelector, useDispatch } from 'react-redux';
import { createToast } from '../../redux/actions/toast';
import Input from '../../component/formfields/Input';
import SubmitButton from '../../component/buttons/SubmitButton';
import UploadField from '../../component/formfields/UploadField';
import { FaTimes } from 'react-icons/fa';
import axiosInstance from '../../utils/axiosInstance';

function RidepointCreate() {
    // page for adding a new car
    // contains a form with fields for name and license plate
    // also contains a upload field for uploading a car image 

    const isLoading = useSelector(state => state.api.isLoading);
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        name: '',
        license_plate: '',
        active: true
    });

    const [uploadedImage, setUploadedImage] = useState(null);
    const [previewURL, setPreviewURL] = useState('');

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleAdd = async (e) => {
        e.preventDefault();

        // Create a FormData object
        const postData = new FormData();
        // Append all fields from formData to FormData object
        for (const key in formData) {
            if (formData.hasOwnProperty(key)) {
                postData.append(key, formData[key]);
            }

        }

        
        if (uploadedImage) {
            // Append the uploaded image
            postData.append('image', uploadedImage);
        }

        try {
            const response = await axiosInstance.post('/api/cars/', postData);
            dispatch(createToast('Car added', 'success'))
        } catch (error) {
            console.error('Error uploading data:', error);
        }
    };

    return (
        <BasePage>
            <div className="flex flex-col items-center mb-10">
                <div className="container">
                    <h1 className="text-4xl font-bold">Add car</h1>

                    <form onSubmit={handleAdd}
                        encType="multipart/form-data"
                    >
                        <div className="w-full md:w-3/4 px-3 md:mb-6 mt-6 md:mb-0">
                            <Input
                                name="name"
                                label="Name"
                                placeholder="Enter car name"
                                required
                                onChangeHandler={handleInputChange}
                                value={formData.name}
                            />
                        </div>
                        <div className="w-full md:w-3/4 px-3 md:mb-6 mt-6 md:mb-0">
                            <Input
                                name="license_plate"
                                placeholder="Enter license plate"
                                label="Licence plate"
                                required
                                onChangeHandler={handleInputChange}
                                value={formData.license_plate}
                            />
                        </div>


                        <div className="w-full md:w-3/4 px-3 md:mb-6 mt-6 md:mb-0">
                            {uploadedImage ? (
                                <div className="relative">
                                    <img src={previewURL} alt="Preview" className="w-64 h-64 object-cover rounded-lg mx-auto" />
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setUploadedImage(null);
                                            setPreviewURL('');
                                        }}
                                        className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full"
                                    >
                                        <FaTimes />
                                    </button>
                                </div>
                            ) : (
                                <div className="">
                                    <UploadField setUploadedImage={setUploadedImage} setPreviewURL={setPreviewURL} />
                                </div>
                            )}
                        </div>

                        
                        <div className="flex items-center justify-center mt-12">
                            <SubmitButton text="Save car" isLoading={isLoading} />
                        </div>
                    </form>
                </div>
            </div>


        </BasePage >

    )
}

export default RidepointCreate
import React from 'react'
import { FaEdit } from 'react-icons/fa';


function EditButton({ text, linkurl }) {
    return (
        <a
            href={linkurl}
            className="btn text-darkblue hover:bg-darkblue hover:text-white rounded-md hover:shadow-md cursor-pointer flex items-center justify-center"
        >
             <FaEdit className="mr-2" />
            <span>{text}</span>
           
        </a>
    )
}

export default EditButton
// component to toggle between table and card view
// takes setView as prop, which is a state setter function that is set to either 'table' or 'card'
// return button that toggles between table and card view

import React from 'react'
import { FaTable, FaGripHorizontal } from 'react-icons/fa'

function TableCardToggle({ view, setView }) {


    return (

        <div>
            <button
                onClick={() => setView('table')}
                className={
                    view === 'table' ?
                    "bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded border-2 border-gray-800" :
                        "bg-gray-100 hover:bg-gray-200 text-gray-800 font-bold py-2 px-4"}
            >
                <FaTable />
            </button>
            <button
                onClick={() => setView('card')}
                className={
                    view === 'card' ?
                        "bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded border-2 border-gray-800" :
                        "bg-gray-100 hover:bg-gray-200 text-gray-800 font-bold py-2 px-4"}
            >
                <FaGripHorizontal />
            </button>
        </div >
    )
}

export default TableCardToggle
import {
    SHOW_TOAST,
    REMOVE_TOAST,
} from "../constants/action-types";


const MAX_TOASTS = 10;

const initialState = {
    toasts: [],

};

export default function (state = initialState, action) {
    switch (action.type) {

        case SHOW_TOAST:
            let newToasts = [...state.toasts, action.payload];
            if (newToasts.length > MAX_TOASTS) {
                newToasts = newToasts.slice(1); // Remove the oldest toast
            }
            return {
                ...state,
                toasts: newToasts,
            };
        case REMOVE_TOAST:
            return {
                ...state,
                toasts: state.toasts.filter((toast) => toast.id !== action.payload),
            };
        default:
            return state;
    }
}


import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/actions/auth';
import { FaBars } from 'react-icons/fa'; 

function AuthNavbarButtons() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  if (!isAuthenticated) {
    return (
      <div className="nav-icons flex items-center xs:order-2 ml-auto">
        <ul className="list-none menu-social mb-0 flex">
          <li className="inline ms-1">
            <a href="/login">
              <button className="btn btn-sm rounded-full hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-orange hover:text-white">
                Login
              </button>
            </a>
            <a href="/signup">
              <button className="btn btn-sm rounded-full hover:bg-orange-700 border-orange-600 hover:border-orange-700 text-orange hover:text-white">
                Signup
              </button>
            </a>
          </li>
        </ul>
      </div>
    );
  } else {
    return (
      <div className="nav-icons flex items-center xs:order-2 ml-auto">
        <ul className="list-none menu-social mb-0">
          <li className="relative inline-block ms-1">
            <button
              className="btn btn-sm text-orange-600 hover:text-orange-700 border-white rounded xs:hidden p-3" 
              onClick={toggleDropdown}
            >
              <FaBars size={25} /> 
            </button>
            <button
              className="hidden xs:inline-block btn btn-sm rounded-full bg-orange-600 hover:bg-orange-700 border-orange-700 hover:text-orange text-white"
              onClick={toggleDropdown}
            >
              Menu
            </button>
            
            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg z-10">
                <ul className="py-1 text-gray-700">
                  <li>
                    <a href="/profile" className="block px-4 py-2 hover:bg-orange-100">
                      Profile
                    </a>
                  </li>
                  <li>
                    <a href="/company/me" className="block px-4 py-2 hover:bg-orange-100">
                      Company
                    </a>
                  </li>
                  <li>
                    <a href="/company/members" className="block px-4 py-2 hover:bg-orange-100">
                      Members
                    </a>
                  </li>
                  <li>
                    <button
                      className="w-full text-left px-4 py-2 hover:bg-orange-100"
                      onClick={() => {
                        dispatch(logout());
                        setDropdownOpen(false); 
                      }}
                    >
                      Logout
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </li>
        </ul>
      </div>
    );
  }
}

export default AuthNavbarButtons;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CarBg from "../../assets/images/bg/car-bg.jpg";
import LogoImage from "../../assets/images/logo-ridetracker.png";
import Switcher from "../../component/Switcher";
import axiosInstance from "../../utils/axiosInstance";
import Input from "../../component/formfields/Input";
import SubmitButton from "../../component/buttons/SubmitButton";
import { useSelector } from "react-redux";


const InviteSuccess = ({ remainingTime }) => {
    return (
        <div className="">
            <h5 className="mb-8 text-xl dark:text-white font-semibold text-center">User invited</h5>
            <div className="ltr:text-left rtl:text-right" >
                <div className="text-center">

                    <p>An email was sent with an invite link!</p>
                </div>

                <div className="text-center">
                    <p>You will be redirected in {remainingTime} seconds</p>
                </div>
            </div>

        </div>
    )
}


export default function Invite() {

    const [formData, setFormData] = useState({})
    const [errors, setErrors] = useState({});
    const [inviteComplete, setInviteComplete] = useState(false)
    const isLoading = useSelector(state => state.api.isLoading)
    const user = useSelector(state => state.auth.user)


    const [remainingTime, setRemainingTime] = useState(5); // initial time set to 5 seconds

    useEffect(() => {

        if (inviteComplete) {
            const countdownInterval = setInterval(() => {
                setRemainingTime(prevTime => {
                    if (prevTime <= 1) {
                        clearInterval(countdownInterval);
                        window.location.href = '/rides';
                    }
                    return prevTime - 1;
                });
            }, 1000);

            // Cleanup interval on component unmount
            return () => {
                clearInterval(countdownInterval);
            };
        }
    }, [inviteComplete]);




    const handleSubmit = (e) => {
        e.preventDefault();

        if (user.company == null || user.company == undefined) {
            setErrors({ "company_id": "User does not belong to a company" })
            return
        }


        // get company id from redux, append to formdata
        const postData = {
            ...formData,
            company_id: user.company
        }


        axiosInstance.post(`/api/v1/auth/invite/`, postData)
            .then(res => {

                setInviteComplete(true)

            })
            .catch(err => {
                console.log(err)
                if (err.response.status == 500) {
                    setErrors({ "error": "Internal server error" })
                }
                if (err.response.status == 400) {
                    // get error from response.data the field changes depending on the error
                    const newErrors = {};

                    for (const field in err.response.data) {
                        if (err.response.data.hasOwnProperty(field)) {
                            newErrors[field] = err.response.data[field];
                        }
                    }
                    setErrors(newErrors);
                }
            })
    }

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }



    return (
        <>
            <section
                style={{ backgroundImage: `url(${CarBg})` }}
                className="position-relative bg-center bg-cover">
                <div className="absolute inset-0 bg-black opacity-90"></div>
                <div className="container-fluid relative">
                    <div className="grid grid-cols-1">
                        <div className="lg:col-span-4">
                            <div className="flex flex-col min-h-screen md:px-12 py-12 px-3">


                                <div className=" my-auto">
                                    <div className="mt-5 w-full max-w-sm m-auto px-6 py-8 bg-white dark:bg-slate-900 rounded-md shadow-lg shadow-slate-500 dark:shadow-slate-800">
                                        <div className="grid grid-cols-1">

                                            <div className="text-center mx-auto">
                                                <Link to="/index"><img src={LogoImage} alt="" className="h-10" /></Link>
                                            </div>
                                            {
                                                inviteComplete ?
                                                    <InviteSuccess remainingTime={remainingTime} />
                                                    :
                                                    <div className="">
                                                        <h5 className="mb-8 text-xl dark:text-white font-semibold text-center">Invite</h5>
                                                        <form className="ltr:text-left rtl:text-right" onSubmit={handleSubmit}>
                                                            <div className="grid grid-cols-1">
                                                                <div className="mb-4">
                                                                    <Input name="email" label="Email" placeholder="Enter email" required onChangeHandler={handleInputChange} value={formData.email} />
                                                                </div>

                                                                <div className="mb-4">
                                                                    <SubmitButton text="Invite" isLoading={isLoading} width="w-full" />
                                                                </div>
                                                                {Object.keys(errors).length > 0 && (
                                                                    <div className="text-red-500 text-center">
                                                                        {Object.entries(errors).map(([field, message]) => (
                                                                            <div key={field}>
                                                                                {message}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                )}


                                                            </div>
                                                        </form>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- Start Footer --> */}
                                <div className="text-center">
                                    <p className="text-gray-400">© {(new Date().getFullYear())}{" "} Ridetracker. Track and manage your rides <i className="mdi mdi-pin text-orange-700"></i></p>
                                </div>
                                {/* <!-- End Footer --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- LTR & RTL Mode Code --> */}
            {/* Switcher section */}
            <Switcher />
        </>
    )
}

// main component for handling authentication and authorization actions
// loads user data from API and stores it in redux store
// handles authorization for protected routes


import {
    LOGOUT, 
    AUTH_ERROR,
    FETCH_USER_SUCCESS,
    SET_AUTHENTICATED
} from '../constants/action-types';

import axiosInstance from '../../utils/axiosInstance';

// CHECK TOKEN & LOAD USER
export const fetchUserData = () => (dispatch) => {

    // check if user is authenticated, data is stored in redux state.auth.isAuthenticated

    axiosInstance
        .get(`/api/v1/auth/users/me/`)
        .then((res) => {
            dispatch({
                type: FETCH_USER_SUCCESS,
                payload: res.data,
              });
        })
        .catch((err) => {
            dispatch({
                type: AUTH_ERROR,
              });
              
        });
};


// LOGOUT USER
export const logout = () => (dispatch) => {

    dispatch({
        type: LOGOUT,
    });

};

// Set Authenticated
export const setAuthenticated = (status) => ({
    type: SET_AUTHENTICATED,
    payload: status,
  });



// DEPRECATED HELPER FUNCTION FOR SETTING TOKEN IN HEADERS
// Setup config with token - helper function
export const tokenConfig = (getState) => {
    // Get token from state
    const accessToken = getState().auth.access;

    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    // If token, add to headers config
    if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
    }

    return config;
};




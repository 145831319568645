import React, { useState, useEffect } from 'react'
import Input from '../../../component/formfields/Input';
import CheckBox from '../../../component/formfields/CheckBox';
import { editCar } from '../../../redux/actions/cars';
import { useDispatch, useSelector } from 'react-redux';
import UploadField from '../../../component/formfields/UploadField';
import SubmitButton from '../../../component/buttons/SubmitButton';
import axiosInstance from '../../../utils/axiosInstance';
import { createToast } from '../../../redux/actions/toast';
import { FaTimes } from 'react-icons/fa';

function CarEditForm({ carData, setCarData, setEditMode }) {
    // section to display car details, used in CarDetail.jsx
    // carData is an object with car details
    // replaces CardetailSection in CarDetail.jsx if editMode is true
    const isLoading = useSelector(state => state.api.isLoading);    
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        name: '',
        license_plate: '',
        active: true
    });


    const [uploadedImage, setUploadedImage] = useState(null);
    const [previewURL, setPreviewURL] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();


        // dispatch form data to api
        // on success, set carData to new data
        dispatch(editCar(carData.id, formData))
            .then(res => {
                setCarData(res);
                setEditMode(false);
            }
            )
            .catch(
                err => {
                    console.log(err);
                }
            )
    }

    const handleUpdate = async (e) => {
        e.preventDefault();

        // Create a FormData object
        const postData = new FormData();
        // Append all fields from formData to FormData object
        for (const key in formData) {
            if (formData.hasOwnProperty(key)) {
                postData.append(key, formData[key]);
            }

        }

        
        if (uploadedImage) {
            // Append the uploaded image
            postData.append('image', uploadedImage);
        }

        try {
            const response = await axiosInstance.put(`/api/cars/${carData.id}/`, postData);
            dispatch(createToast('Car updated', 'success'))
        } catch (error) {
            console.error('Error uploading data:', error);
        }
    };


    // set form data to car data, so that form is pre-filled with car data
    useEffect(() => {
        setFormData(
            {
                name: carData.name,
                license_plate: carData.license_plate,
                active: carData.active
            }
        )
    }, [carData]);

    // date options for date formatting
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', timeZoneName: 'short' };


    // helper functions to handle input change
    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleCheckboxChange = (e) => {
        setFormData({ ...formData, active: e })
    }

    return (
        <form onSubmit={e => handleUpdate(e)}>
            <div className="mt-6 border-t border-gray-100">
                <dl className="divide-y divide-gray-100">
                    <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Name:</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <Input
                                name="name"
                                labelDisabled={true}
                                label="Name"
                                required onChangeHandler={handleInputChange}
                                value={formData.name} />
                        </dd>
                    </div>

                    <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">License plate:</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <Input
                                name="license_plate"
                                labelDisabled={true}
                                required onChangeHandler={handleInputChange}
                                value={formData.license_plate} />
                        </dd>
                    </div>
                    <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Active:</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <CheckBox
                                checked={formData.active}
                                labelDisabled={true}
                                onChangeHandler={e => handleCheckboxChange(e)}
                                name="active"
                                label="Active"
                            />
                        </dd>
                    </div>

                    <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Created:</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {carData.created_at && new Intl.DateTimeFormat('en-EU', options).format(new Date(carData.created_at))}
                        </dd>
                    </div>
                    <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Updated:</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {carData.updated_at && new Intl.DateTimeFormat('en-EU', options).format(new Date(carData.updated_at))}
                        </dd>
                    </div>

                    <div className="w-full md:w-3/4 px-3 md:mb-6 mt-6 md:mb-0">
                        {uploadedImage ? (
                            <div className="relative">
                                <img src={previewURL} alt="Preview" className="w-64 h-64 object-cover rounded-lg mx-auto" />
                                <button
                                    type="button"
                                    onClick={() => {
                                        setUploadedImage(null);
                                        setPreviewURL('');
                                    }}
                                    className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full"
                                >
                                    <FaTimes />
                                </button>
                            </div>
                        ) : (
                            <div className="">
                                <UploadField setUploadedImage={setUploadedImage} setPreviewURL={setPreviewURL} />
                            </div>
                        )}
                    </div>

                    <div className="px-2 py-4 ">

                        <div className="flex items-center justify-center mt-2">
                            <SubmitButton text="Save car" isLoading={isLoading} />
                            {/* <button
                                type="submit"
                                id="submit"
                                name="submit"
                                className="btn bg-orange-500 hover:bg-orange-500 border-orange-600 hover:border-orange-500 text-white rounded-md h-11 justify-center flex items-center"
                            >
                                Save
                            </button> */}

                        </div>
                    </div>

                </dl>
            </div>
        </form >
    )
}

export default CarEditForm
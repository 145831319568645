import React from 'react'
import { FaCheck, FaTimes } from 'react-icons/fa'

function ProfileInfo({ data }) {

    return (
        <div>
            <div className="mt-6 border-t border-gray-100">
                <dl className="divide-y divide-gray-100">
                    <div className="px-2 py-4 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0">
                        Profile Info
                    </div>
                    <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Email </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {data.email}
                        </dd>
                    </div>

                    <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">First name </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {data.first_name}
                        </dd>
                    </div>

                    <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Last name </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {data.last_name}
                        </dd>
                    </div>
                    <div className="px-2 py-4 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0">
                        Preferences
                    </div>
                    <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Theme</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {data?.theme}
                        </dd>
                    </div>

                    {/* <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Default car</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            {data?.preferences?.default_car}
                        </dd>
                    </div> */}
                 

{/* 
                    <div className="px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Allow others to create rides for me </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <span className="whitespace-nowrap">

                                {data?.allow_others_to_create_rides ? (
                                    <FaCheck className="text-green" />
                                ) : (
                                    <FaTimes className="text-red" />
                                )}
                            </span>
                        </dd>
                    </div> */}



                </dl>
            </div>
        </div>
    )
}

export default ProfileInfo
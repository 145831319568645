
import React from 'react';

function Toggle({ checked, onChangeHandler, label}) {


    return (
        <label className="relative inline-flex items-center cursor-pointer">
            <input
                type="checkbox"
                value=""
                className="sr-only peer"
                checked={checked}
                onChange={onChangeHandler}
            />

            {/* gray if checked is false, orange it checked is true */}
            <div className="w-11 h-6 bg-gray-200 peer-checked:bg-orange-500 rounded-full peer-checked:ring-4 peer-checked:ring-orange-300 dark:peer-checked:ring-orange-800 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600"></div>



            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">{label}</span>
        </label>
    )

}

export default Toggle; 

import {
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  FETCH_USER_SUCCESS,
  SET_AUTHENTICATED,
} from '../constants/action-types';


const initialState = {
  isAuthenticated: !!localStorage.getItem('access'),
  user: null
  
};

export default function (state = initialState, action) {
  switch (action.type) {
    
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
      };
    case AUTH_ERROR:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    case LOGIN_FAIL:
    case LOGOUT:
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    case SET_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    default:
      return state;
  }
}
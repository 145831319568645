import {
    API_CALL_START,
    API_CALL_SUCCESS,
    API_CALL_FAILURE,
  } from '../constants/action-types';
  
  export const apiCallStart = () => ({
    type: API_CALL_START,
  });
  
  export const apiCallSuccess = () => ({
    type: API_CALL_SUCCESS,
  });
  
  export const apiCallFailure = () => ({
    type: API_CALL_FAILURE,
  });